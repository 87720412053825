import axios from "axios";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login";
import { useEffect, useState } from "react";
// import Sidenav from "./Components/Sidenav";
import Perspective from "./Pages/Perspective";
import { useAuth0 } from '@auth0/auth0-react';
import isSandboxEnvironment from "./utils/is-sandbox-environment";


// Backend Host URL used for API Calls
export const API = "https://tvldpwwfs1.execute-api.us-east-1.amazonaws.com"

function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(sessionStorage.getItem("isLogged"));
  const { isLoading, isAuthenticated, error} = useAuth0();
  

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("isLogged") === "true";
    setIsUserLoggedIn(loggedIn);
    console.log('LocalStorage isLogged:', loggedIn);
  }, []);

  useEffect(() => {
    console.log('Auth0 isAuthenticated:', isAuthenticated);
  }, [isAuthenticated]); 
  
  console.log(isUserLoggedIn);  
  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  const isLoggedIn = isAuthenticated || isUserLoggedIn;

  return (
    isLoggedIn ? (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Main />} />               
          </Routes>
        </BrowserRouter>
      </div>
    ) : (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} /> 
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
  );
}

// Home Routes
const Main = () => {
  const navigate = useNavigate();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
  const endpoint = isSandboxEnvironment() ? 'tqg.us.auth0.com' : 'tqg-prod-one.us.auth0.com';

  useEffect(() => {
    (async() => {
      if(!isLoading) {
        const isUserLoggedIn = sessionStorage.getItem("isLogged");
        if (isAuthenticated) {
          console.log('redirecting');
          const fetchedToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://${endpoint}/api/v2/`,
              scope: 'profile email read:users'
            }
          });
          if (fetchedToken) {
            setAccessToken(fetchedToken);
            let header = {};
            header["Authorization"] = `Bearer ${fetchedToken}`;
            const res = await axios.post(
              `${API}/auth/`,
              {
                user: "",
                pwd: "",
              },
              {
                headers: header,
                withCredentials: true,
              }
            );
            console.log(res);
            if (res.status === 200) {
              if(res.data.session) {
                sessionStorage.setItem("sessionToken", res.data.session);
              } else {
                const cookies = document.cookie.split(';');
                const sessionCookie = cookies.find(cookie => cookie.trim().startsWith('session='));
                if (sessionCookie) {
                  const sessionToken = sessionCookie.split('=')[1];
                  sessionStorage.setItem("sessionToken", sessionToken);
                }
              }
              sessionStorage.setItem("Username", user.email);
              sessionStorage.setItem("isLogged", true);
              navigate("/app");
            }
          }
          
        } else if (isUserLoggedIn) {
          console.log('user logged in');
          setIsUserLoggedIn(true);          
        } else {
          navigate("/login");
        }
      }
    })();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {(isUserLoggedIn || isAuthenticated) && 
        <Routes>
          <Route path="/app" element = {<Perspective accessToken={accessToken} />} />
        </Routes>
      }
    </>
  );
}

export default App;
